<template>
<div class="inner-template">
    <h1 class="ml-4 my-4">Profile Settings</h1>
    <div class="settings component" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start">
        <v-card color="white" class="ma-0 col-12 mb-2" elevation="4">
        <div>

            <v-list>
              <v-list-item v-for="(item, i) in settingLinks" :key="`setting-${i}`" :to="item.path" class="item">
                <v-list-item-icon>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{item.text}}</v-list-item-title>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="logout" class="item">
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>

        </div>
      </v-card>
      </v-container>
    </div>
</div>
</template>

<script>

import { cloud, storage, db } from '@/firebase';
import { mapGetters } from 'vuex'

export default {
  name: "Settings",

  computed:{
     getPercentages(arr){
        return true;
    },
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
  },
  data(){
    return{
      calAuthUrl:false,
      isLoaded: false,
      showNewAccount: false,
      profileChecker: "",
      localUser:[],
      showAvatar:false,
      avatarType: false,
      preferAvatar: false,
      displayName: "",
      selectedTab: 0,
      snackbar:{
        show:false,
      },
      settingLinks:[
      {icon:"mdi-card-account-details-outline",text:"Edit Profile", path:'/edit-profile'},
      {icon:"mdi-clock-outline",text:"Calendar Settings"},
      {icon:"mdi-credit-card-outline",text:"Payment"},
      {icon:"mdi-bank",text:"Billing"},
      // {icon:"mdi-calendar-month-outline",text:"Calendar"},
      ],
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  watch:{
    '$route': 'getUser'
  },
  methods:{
    getUser(){
      let vThis = this;
      this.$store.dispatch('getCurrentUser').then(results =>{
        process.env.NODE_ENV === "development" ? console.log( 'results from get user', results ) : null;
        this.localUser = results.data;
        // if(this.user !== null || this.user !== 'undefined'){
        //   // this.$route.params.userId = this.user
        //   process.env.NODE_ENV === "development" ? console.log('params',this.$route.params.userName , this.user.userName, this.$route.params.userName === this.user.userName ) : null;
        //   if(this.$route.params.userName === this.user.userName){
        //     this.localUser = this.user;
        //   }
        //   else{
        //     process.env.NODE_ENV === "development" ? console.log( 'grabbing user???' ) : null;
        //     vThis.loadUser();
        //   }
        // }

      }).catch(error =>{
          vThis.loadUser();
      })
      // let vThis = this;
      // this.$store.dispatch('getCurrentUser').then(result=>{
      //   process.env.NODE_ENV === "development" ? console.log( result ) : null;
      //   db.collection('users')
      //   .doc(result.userId)
      //   .get()
      //   .then(snapshot => {
      //     vThis.localUser = snapshot.data()
      //     // do something with document
      //   })
      // })
    },
    loadUser(){
      let vThis = this;
      db.collection('users').where('userName', '==', this.$route.params.userName).get().then((result)=>{
        if (result.empty) {
          console.log('No matching documents.');
          return;
        }

        result.forEach(doc => {
          console.log(doc.id, '=>', doc.data());
          vThis.localUser = doc.data();
        });
      })
    },
    logout(){
      this.$store.dispatch('logout');
    }
  },
  mounted(){
    this.getUser();
  }
};
</script>


<style lang="scss">
.settings.component{
  // background-color: #f2f2f2;
  position: relative;

  .profile-photo{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      // border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .header{
    position: relative;
    .name{
      line-height: 30px;
    }
    .edit{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .profile-details{
      .profile-img{
        // max-width: 80vw;
        // border-radius: 0;
        // border: none;
      }
    }
    .meet-me.v-btn{
      // margin-top: -20px;
      border-radius: 6px;
      // text-shadow: 1px 1px 5px rgba(0,0,0,.60);
      background-color: rgba(0,0,0,.35)!important;
      border: 3px solid white!important;
    }
  }
}

</style>
